/* FONT IMPORTS */
/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:300, 400, 700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Roboto:300, 400, 500); */
@import url(https://fonts.googleapis.com/css?family=Kanit);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #fff;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  font-family: "Kanit", sans-serif;
  font-size: 1em;
  font-weight: 200;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
}

header {
  font-family: "Kanit", sans-serif;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 1px;
  padding-left: 3em;
  padding-right: 3em;
  border-bottom: 1px solid var(--border-color);
  background: #0088cc;
}

h1 {
  font-size: 1.5em;
  max-width: 300px;
}

main {
  font-family: "Kanit", sans-serif;
  margin-top: 1em;
  margin-right: 3em;
  margin-left: 3em;
}

.head-topic {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-text {
  color: #fff;
  text-decoration: none;
}
.title-text {
  font-size: large;
  /* margin-bottom: 100px; */
}
.header-icon {
  margin-right: 0.5rem;
  fill: #fff;
}
.dropdown-header {
  font-family: "Material Icons", serif;
  font-size: 1.5em;
}

.dropdown-header:hover {
  cursor: pointer;
}

.dropdown-header-item-container {
  position: absolute;
  top: 50px;
  right: 3em;
  padding: 5px;
  background: #08c;
}

.dropdown-header-item-container:hover {
  cursor: pointer;
}

.add-patient {
  margin: 10px;
  padding: 10px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  outline: none;
  -webkit-appearance: none;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.panel {
  display: flex;
  justify-content: space-between;
}
.half-panel {
  margin-right: 10px;
  width: 50%;
  min-width: 450px;
}
/* .video  { 
  position: relative; 
  width: 250px; 
  height: 250px; 
  float: left; 
  margin-left: 20px; 
} */
.video-panel {
  border: 1px black solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.video-wrapper {
  margin: 5px;
  position: relative;
}
.video-wrapper .overlay-button {
  font-family: "Material Icons", serif;
  font-size: 1.5em;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.video-wrapper .overlay-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.video-wrapper .overlay-button-del {
  font-family: "Material Icons", serif;
  font-size: 1.5em;
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 1;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.video-wrapper .overlay-button-del:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

/* FORM STUFF BEGINS HERE */
form {
  padding: 20px;
  border: 1px black solid;
  border-bottom: 1px solid --border-color;
  /* margin-bottom: 20px; */
}

form.inline {
  display: flex;
  align-items: baseline;
}

label {
  font-size: 14px;
}

input {
  line-height: normal;
  height: auto;
  width: 100%;
  margin: 0 0 10px 0;
  font-size: 16px;
  padding: 0.5em 0.5em;
  display: block;
  background-color: #fff;
  color: #333;
  position: relative;
  border: 1px solid #ccc;
}
input[type="radio"] {
  width: auto;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.searchbox {
  width: auto;
}

.input-feedback {
  color: red;
}

.radio {
  display: flex;
  flex-direction: row;
}
.dropdown {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  margin: 0 0 10px 0;
  border: 1px solid #ccc;
  background-color: #fff;
}
.dropdown-content {
  /* display: none; */
  /* position: absolute; */
  position: relative;
  background-color: #f9f9f9;
  min-width: 160px;
  max-height: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}
.dropdown-item {
  padding: 5px 10px;
}
.dropdown-item:hover {
  background-color: #d9e2ff;
  cursor: pointer;
}

.dropdown-divider {
  margin-top: 0.4em;
  padding-top: 0.4em;
  border-top: 0.1rem solid #ccc;
}

/* .dropdown .dropdown-content {
  display: block;
} */

.dropdown-more {
  background-color: #f7eee6;
}
.dropdown-more-content {
  /* display: none; */
  /* position: right; */
  /* min-width: 160px; */
  /* //max-height: 200px; */
  /* //box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 2;
  /* //overflow-y: scroll; */
  /* background-color: #f9f9f9; */
  background-color: #f7eee6;
}
.dropdown-more:hover {
  /* background-color: #d9e2ff; */
  cursor: pointer;
}

/* .dropdown-more .dropdown-more-content {
  display: block;
} */

/* .dropdown-more-item:hover .dropdown-hidden-item {
  display: block;
} */

.dropdown.drug {
  width: 50%;
  height: 40px;
}
.seperate-field {
  margin-top: 1em;
  padding-top: 1em;
  border-top: thin solid;
}

.med-container {
  background-color: #ccc;
  display: flex;
  align-items: center;
}
.med-container .med-select {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: inline-flex;
}

.med-container .med-amount {
  width: 20%;
  height: 40px;
}

.med-container label {
  display: inline-flex;
}
.med-container label {
  display: inline-flex;
}

.mybutton {
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  width: 40%;
}
.mybutton:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.mybutton.delete {
  margin-left: 0.3em;
  background-color: #fff;
  width: 40px;
  height: 40px;
  background: url(./drawing/delete.png) center no-repeat;
  border: red solid 0.5px;
}
.mybutton + .mybutton {
  margin-left: 0.5rem;
}

.mybutton.outline {
  background-color: #fff;
  border: 1px solid #08c;
  color: #08c;
  width: auto;
}

/* Chat */

.Messages-list {
  max-width: 900px;
  width: 100%;
  height: 400px;
  margin: 0 auto;
  padding: 20px;
  list-style: none;
  flex-grow: 1;
  overflow: auto;
  border: 1px black solid;
}

.Messages-message {
  display: flex;
  margin-top: 10px;
}

.Messages-message.currentMember {
  /*justify-content: flex-end;*/
  flex-direction: row-reverse;
  text-align: right;
}

.Message-content {
  display: inline-block;
}

.currentMember > .Message-content {
  align-items: flex-end;
}

.Messages-message > .avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px -10px;
}

.Message-content > .username {
  display: block;
  color: gray;
  font-size: 14px;
  padding-bottom: 4px;
}

.Message-content > .text {
  padding: 10px;
  max-width: 400px;
  margin: 0;
  border-radius: 12px;
  background-color: #daeef7;
  color: black;
  display: inline-block;
  word-wrap: break-word;
}

.currentMember > .Message-content .text {
  background-color: #08c;
  color: white;
}

.Message-button {
  font-family: "Material Icons", serif;
  font-size: 1.5em;
  padding: 12px 20px;
  margin-left: 12px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}
