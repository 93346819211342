div.options > label > input {
    visibility: hidden;
}

/*
div.options > label {
    display: block;
    margin: 0 0 0 -10px;
    padding: 0 0 20px 0;  
    height: 20px;
    width: 150px;
}
*/

div.options > label > img {
    display: inline-block;
    padding: 0px;
    height:30px;
    width:30px;
    /* background: none; */
    background-color: white;
}

div.options > label.checkbox1 > input:checked +img {  
    background: url(../../drawing/pass.png);
    background-repeat: no-repeat;
    background-position:center center;
    background-size:30px 30px;
}

div.options > label.checkbox2 > input:checked +img {  
    background: url(../../drawing/fail.png);
    background-repeat: no-repeat;
    background-position:center center;
    background-size:30px 30px;
}


div.options > label.checkbox10 > input:checked +img {  
    background: url(../../drawing/pass.png);
    background-repeat: no-repeat;
    background-position:center center;
    background-size:30px 30px;
    background-color: white;
}

div.options > label.checkbox20 > input:checked +img {  
    background: url(../../drawing/fail.png);
    background-repeat: no-repeat;
    background-position:center center;
    background-size:30px 30px;    
    background-color: white;
}


/* .container { position: relative; width: 200px; height: 200px; float: left; margin-left: 20px; } */
.container2 { position: relative; height: 440px; float: left; margin-left: 0px; }
/* .container1 { position: relative; width: 100px; height: 100px; float: left; margin-left: 20px; } */

.video2  { position: relative; height: 440px; float: left; margin-left: 0px; }
/* .video  { position: relative; width: 200px; height: 200px; float: left; margin-left: 20px; } */
.checkbox { position: absolute; bottom: 0px; right: 0px; }
.checkbox10 { position: absolute; bottom: 0px; left: 0px; z-index: 2; }
.checkbox20 { position: absolute; bottom: 0px; right: 0px; z-index: 2; }
